<template>
  <div>
    <b-nav-item-dropdown v-model="$i18n.locale">
      <!-- Using 'button-content' slot -->
      <template #button-content>
        <em class="uppercase">
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'globe' }" />
          {{ $i18n.locale }}
        </em>
      </template>
      <b-dropdown-item
        v-for="(lang, i) in $i18n.availableLocales"
        :key="`Lang${i}`"
        class="uppercase"
        href="#"
        :value="lang"
        @click="setLocale(lang)"
      >
        {{ lang }}
      </b-dropdown-item>
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import eventBus from "./Helpers/eventBus";

export default {
  name: "LanguageSwitcher",
  data () {
    return {
      placeholders: {
        en: "ca",
        fr: "fr",
      },
    };
  },
  methods: {
    async setLocale (locale) {
      this.$i18n.locale = locale;
      await this.$store.dispatch("getDecks");
      await this.$store
        .dispatch("getAppTexts")
        .then(() => {
          this.$emit("languageChange", locale);
          eventBus.$emit("languageChange", locale);
          this.$router.push({
            params: { lang: locale },
          });
        })
        .catch((err) => {
          // @TODO: Replace this
          alert(err);
        });
    },
  },
};
</script>

<style lang="scss">
.uppercase {
  text-transform: uppercase !important;
}
</style>
