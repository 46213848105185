import Vue from "vue";
import VueToast from "vue-toast-notification";
import { router, routerMix } from "./components/Routes/Router";
//import {quotes} from './components/Helpers/Quotes.js'
import store from "./components/store/store";
import i18n from "./components/i18n";
import App from "./App.vue";
import Vuex from "vuex";
/** Plugins */
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faArrowLeft, faGlobe, faUser, faCheck, faQuestion } from "@fortawesome/fontawesome-free-solid";
import { faFacebook, faInstagram, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import VueCountdownTimer from "vuejs-countdown-timer";
import "vue-toast-notification/dist/theme-sugar.css";

Vue.use(VueToast);
Vue.use(router);
Vue.use(VueCountdownTimer);

library.add(faArrowLeft, faGlobe, faUser, faCheck, faQuestion);
library.add(faFacebook);
library.add(faInstagram);
library.add(faLinkedin);

Vue.component("FontAwesomeIcon", FontAwesomeIcon);
/** Components for pages */
Vue.config.productionTip = false;
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(Vuex);
/** Lighting up the app */

new Vue({
  el: "#app",
  i18n,
  router,
  VueToast,
  store,
  mixins: [routerMix],
  ...App,
});
