import i18n from "../i18n";
import Shuffle from "../Helpers/Shuffle";

const SUBSCRIPTION_TYPES = {
  FREE: "Free",
  SILVER: "Silver",
  GOLD: "Gold",
};

const USER_ROLES = {
  BASIC: "Basic",
  ADMIN: "Admin",
  OWNER: "Owner",
};

const authorizations = {
  [SUBSCRIPTION_TYPES.GOLD]: {
    [USER_ROLES.BASIC]: {
      templateCreation: false,
      logo: false,
      addUser: false,
      seeOrganizationUser: false,
      manageAccounts: false,
      templateEdit: false,
    },
    [USER_ROLES.ADMIN]: {
      templateCreation: true,
      logo: false,
      addUser: true,
      seeOrganizationUser: true,
      manageAccounts: true,
      templateEdit: true,
    },
    [USER_ROLES.OWNER]: {
      templateCreation: true,
      logo: true,
      addUser: true,
      seeOrganizationUser: true,
      manageAccounts: true,
      templateEdit: true,
    },
  },
  [SUBSCRIPTION_TYPES.SILVER]: {
    [USER_ROLES.BASIC]: {
      templateCreation: false,
      logo: false,
      addUser: false,
      seeOrganizationUser: false,
      manageAccounts: false,
      templateEdit: true,
    },
    [USER_ROLES.ADMIN]: {
      templateCreation: false,
      logo: false,
      addUser: true,
      seeOrganizationUser: true,
      manageAccounts: true,
      templateEdit: true,
    },
    [USER_ROLES.OWNER]: {
      templateCreation: false,
      logo: false,
      addUser: true,
      seeOrganizationUser: true,
      manageAccounts: true,
      templateEdit: true,
    },
  },
  [SUBSCRIPTION_TYPES.FREE]: {
    [USER_ROLES.BASIC]: {
      templateCreation: true,
      logo: true,
      addUser: true,
      seeOrganizationUser: true,
      manageAccounts: false,
      templateEdit: true,
    },
    [USER_ROLES.ADMIN]: {
      templateCreation: true,
      logo: true,
      addUser: true,
      seeOrganizationUser: true,
      manageAccounts: true,
      templateEdit: true,
    },
    [USER_ROLES.OWNER]: {
      templateCreation: true,
      logo: true,
      addUser: true,
      seeOrganizationUser: true,
      manageAccounts: true,
      templateEdit: true,
    },
  },
};

const getAuthorizations = (subscriptionType, role) => {
  if (!subscriptionType || !role) return {
    templateCreation: false,
    logo: false,
  };

  return authorizations[subscriptionType][role];
};

export const getters = {
  getStandardTemplateName (state) {
    return state.standardTemplateName;
  },
  selectedDecks (state) {
    return state.selectedDecks;
  },
  standardTemplates (state) {
    return state.standardTemplates;
  },
  getTemplates: (_, { getOrganization }) => (deck) => {
    return getOrganization.templates.filter(template => template.deck == deck);
  },
  API_URL (state) {
    return state.API_URL;
  },
  getRules (state) {
    return state.rules;
  },
  getOrganizations (state) {
    return state.organizations;
  },
  getOrganization (state) {
    return state.organizations[0];
  },
  isFreeOrGold (_, getters) {
    const subscriptionType = getters.getOrganization?.subscription;
    return subscriptionType ? (subscriptionType === SUBSCRIPTION_TYPES.FREE || subscriptionType === SUBSCRIPTION_TYPES.GOLD) : false;
  },
  getJWT (state) {
    //remove this once is done
    return state.jwt;
  },
  getLang (state) {
    return state.lang;
  },
  getOrganizationMembers (state) {
    return state.organizationMembers;
  },
  getTextsLoaded (state) {
    return state.textsLoaded;
  },
  getMyGames (state) {
    return state.games;
  },
  getWink (state) {
    return state.wink;
  },
  getShuffled (state) {
    return state.shuffled;
  },
  getQuotes (state) {
    return state.quotes?.[i18n.locale] || [];
  },
  getHeight (state) {
    return state.heightFix;
  },
  getAppTexts (state) {
    return typeof state.appTexts[i18n.locale] !== "undefined" ? state.appTexts[i18n.locale] : {};
  },
  getFaq (state) {
    return state.faq[i18n.locale];
  },
  getGameType (state) {
    return state.gametype;
  },
  getSelectedDecks (state) {
    return state.selectedDecks;
  },
  getInstr (state) {
    return state.instructions[i18n.locale];
  },
  getStackedDeck (state) {
    return state.stackeddecks[i18n.locale];
  },
  getDeck: state => (id) => {
    if (id !== null) return state.decks[i18n.locale].find(deck => deck.id == id);
    const [deckId, templateId] = Object.entries(state.selectedDecksAndTemplates)?.[0];
    return state.decks[i18n.locale].find(deck => deck.id == deckId)?.templates.find(template => template.id == templateId);
  },
  getDecks (state) {
    return state.decks;
  },
  getUserData (state) {
    return state.user;
  },
  loggedIn (state) {
    return state.jwt !== null;
  },
  getInstrucitons (state) {
    return state.instructionsNew;
  },
  organizationLogo (state) {
    return state.organizations[0]?.logo ? state.organizations[0]?.logo.url : null;
  },
  user (state) { return state.user;},
  authorizations (_, { getOrganization: organization, user }) {
    return organization ? getAuthorizations(organization?.subscription, user.hats?.[0].role) : null;
  },
  decks (state) {
    return state.decks[i18n.locale];
  },
  users (state) {
    return state.organizations[0].hats.map(hat => hat.user);
  },
  getBaseTemplateCards: () => (template, selectedDeck, selectedCards) => {
    const allCards = selectedDeck?.cards ?? [];
    const lowerCaseTemplate = template?.toLowerCase();
    if (lowerCaseTemplate == "standard") {
      return allCards || [];
    } else if (lowerCaseTemplate == "wink" || lowerCaseTemplate == "clin d'œil" || lowerCaseTemplate == "wink") {
      return allCards.filter(card => card.winks);
    } else if (lowerCaseTemplate == "feedback"){
      return allCards.filter(card => !card.winks);
    }

    return selectedCards ? [...selectedCards, ...allCards.filter((card) => {
      const found = selectedCards.find(selectedCard => selectedCard.id == card.id);

      return !found;
    }).map(card => ({ ...card, enabled: false }))] : allCards;
  },
  getSelectedDecksAndTemplates (state) {
    return state.selectedDecksAndTemplates;
  },
  getCardsFromSelectedDeckAndTemplates (_, { getDecks, getBaseTemplateCards, getSelectedDecksAndTemplates, loggedIn }) {
    const deckArray = getDecks[i18n.locale];
    if (!getSelectedDecksAndTemplates || deckArray.length < 1) return null;
    
    const cards = Object.entries(getSelectedDecksAndTemplates).reduce((cardsWithColors, [deckId, templateId]) => {
      const deck = deckArray.find(deck => deck.id == deckId);
      const template = deck?.templates?.find(template => template.id == templateId) ?? null;
      const cards = template ? template?.cards : getBaseTemplateCards(`${templateId}`, deck, []);

      return [
        ...cardsWithColors,
        ...cards.map(card => ({ ...card, bgColor: deck.bg_color, textColor: deck.text_color })),
      ];
    }, []); 


    return Shuffle(loggedIn ? cards.filter(card => card.enabled) : cards.filter(card => card.demo));
  },
  isStillAllowedToPlay (state){ return state.isStillAllowedToPlay; },
  gamesPerHat (_ , { getOrganization }) {
    return getOrganization?.games.reduce((games, game) => {
      const hatId = typeof game.created_by_hat === "object" ? game.created_by_hat.id : game.created_by_hat;
      return { ...games, [hatId]: games[hatId] ? games[hatId] + 1 : 1 };
    }, {});
  },
};