<template>
  <div
    v-if="loading"
    id="app"
  >
    <div class="overlay">
      <div class="loader-container">
        <radar-spinner
          :animation-duration="1500"
          :size="60"
          color="#ff1d5e"
        />
      </div>
    </div>
  </div>
</template>

<script>
import { RadarSpinner } from "epic-spinners";
export default {
  components: {
    RadarSpinner,
  },
  props: {
    loading: {
      type: Boolean,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  //background-color: rgba(0, 0, 0, 0.25);
  top: 0px;
  left: 0px;
}

.loader-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}
</style>