import i18n from "../i18n";
import { PageName } from "../Helpers/PageName";
const routes = [
  {
    path: "/",
    redirect: `/${i18n.locale}`,
  },
  {
    path: "/reset-password",
    redirect: `/${i18n.locale}/reset-password`,
  },
  {
    path: "/register",
    redirect: `/${i18n.locale}/register`,
  },
  {
    path: "/:lang",
    component: {
      render (c) { return c("router-view"); },
    },
    children: [
      { path: "", name: "Home", component: () => import("../Home.vue"), meta: { title: PageName(i18n.t("home")) } },
      {
        path: "account",
        component: {
          render (c) { return c("router-view"); },
        },
        children: [
          { path: "view", name: "Account-View", component: () => import("../Auth/Account.vue"), meta: { title: PageName(i18n.t("home")) } },
        ],
      },
      { path: "my-cards", name: "MyCards", component: () => import("../MyCards.vue"), meta: { title: PageName(i18n.t("home")) } },
      { path: "choose-cards", name: "ChooseCards", component: () => import("../ChooseCards.vue"), meta: { title: PageName(i18n.t("home")) } },
      { path: "create-template", name: "CreateTemplate", component: () => import("../CreateTemplate.vue"), meta: { title: PageName(i18n.t("home")) } },
      {
        path: "manage-accounts",
        component: {
          render (c) { return c("router-view"); },
        },
        children: [
          { path: "", name: "ManageAccounts", component: () => import("../ManageAccounts.vue"), meta: { title: PageName(i18n.t("home")) } },
          { path: ":account", name: "ManageAccount", component: () => import("../ManageAccount.vue"), meta: { title: PageName(i18n.t("home")) } },
        ],
      },
      { path: "add-member", name: "AddOrganizationMember", component: () => import("../AddNewAccount.vue"), meta: { title: PageName(i18n.t("home")) } },
      { path: "forgot-password", name: "Forgot", component: () => import("../Auth/Forgot.vue"), meta: { title: PageName(i18n.t("home")) } },
      { path: "reset-password", name: "Reset", component: () => import("../Auth/Reset.vue"), meta: { title: PageName(i18n.t("home")) } },
      { path: "faq", name: "Faq", component: () => import("../Faq.vue"), meta: { title: PageName(i18n.t("faq")) } },
      { path: "rules", name: "Rules", component: () => import("../Rules.vue"), meta: { title: PageName(i18n.t("rules")) } },
      { path: "tutorials", name: "Tutorials", component: () => import("../Tutorials.vue"), meta: { title: PageName(i18n.t("tutorials")) } },
      { path: "instructions", name: "Instructions", component: () => import("../Game/Instructions.vue"), meta: { title: PageName("Play") } },
      {
        path: "play",
        component: {
          render (c) { return c("router-view"); },
        },
        children: [
          { path: "", name: "Play", component: () => import("../Game/GameStage.vue"), meta: { title: PageName("Play") } },
          { path: "standard", name: "PlayStandard", component: () => import("../Game/GameStage.vue"), meta: { title: PageName("Play") } },
          { path: "by-deck/:deck", name: "PlayByDeck", component: () => import("../Game/GameStage.vue"), meta: { title: PageName("Play") } },
        ],
      },
      { path: "login", name: "Login", component: () => import("../Auth/Login.vue"), meta: { title: PageName("Login") } },
      { path: "register", name: "Register", component: () => import("../Auth/Register.vue"), meta: { title: PageName("Register") } },
      { path: "logout", name: "Logout", component: () => import("../Auth/Logout.vue"), meta: { title: PageName("Logout") } },
    ],
  },
];

export default routes;