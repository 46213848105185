<template>
  <div>
    <Announce v-if="textsLoaded" />
    <Navbar
      v-if="textsLoaded"
      @languageChange="languageChange"
    />
    <b-container fluid>
      <b-row
        :style="{
          height: `${height}px`,
        }"
      >
        <b-col class="w-100 mt-1 pl-0">
          <Loading :loading="$store.state.load" />
          <router-view v-if="textsLoaded"></router-view>
        </b-col>
      </b-row>
      <b-row
        v-if="!isMobile && routeName == 'Home'"
        id="made-by"
        class="mt-5 mb-1"
        style="width: 100%; text-align: center; margin: 0px; padding: 0px"
      >
        <b-col class="w-100 text-center">
          <h3>
            <a
              class="madeby"
              href="https://www.hubu.ca"
              target="_blank"
            >
              {{ $t("madewith") }}
              <b-icon
                class="hubu-red"
                icon="heart-fill"
              />
            </a>
          </h3>
          <h3>
            <a
              class="madeby"
              href="https://www.hubu.ca"
              target="_blank"
            >
              &nbsp;{{ $t("by") }}&nbsp;
              <img
                class="footer-logo"
                src="@/assets/hubu.webp"
              />
            </a>
          </h3>
        </b-col>
      </b-row>
    </b-container>
  </div>
</template>

<script>
import Navbar from "./components/Navbar.vue";
import Loading from "./components/Loading.vue";
import Announce from "./components/Announce.vue";
import Cookies from "js-cookie";
import { mapGetters, mapActions } from "vuex";
import eventBus from "./components/Helpers/eventBus";

export default {
  name: "App",
  components: {
    Navbar,
    Loading,
    Announce,
  },

  computed: {
    ...mapGetters(["user", "loggedIn"]),
    textsLoaded () {
      return this.$store.getters.getTextsLoaded;
    },
    routeName () {
      return this.$route.name;
    },
    isMobile () {
      return (Cookies.get("mobile") === "false") ? false : true;
    },
    height: {
      get () {
        return this.$store.getters.getHeight;
      },
    },
  },

  watch:{
    async $route (to, from){
      if (to !== from && !this.user && this.loggedIn) {
        await this.getMe();
      }
      this.show = false;
    }, 
  },
  async beforeMount () {    
    eventBus.$on("languageChange", async (locale) => {
      await this.$store.dispatch("updateAccount", { lang: locale });
      this.$i18n.locale = locale;
      await this.$store.dispatch("getMe");
      await this.$store.dispatch("getDecks");
      await this.$store.dispatch("getAppTexts");
    });
    
    await Promise.all([this.$store.dispatch("getQuotes"), this.$store.dispatch("getAppTexts")]);
    window.addEventListener("resize", this.handleResize);
  },

  methods: {
    ...mapActions(["getMe"]),
    handleResize () {
      this.$store.dispatch("getHeight");
    },
    languageChange () {
      this.$emit("languageChange");
    },
  },
};
</script>

<style lang="scss">
@import "styles/main.scss";

@font-face {
  font-family: "Visuelt";
  src: url("./assets/fonts/Visuelt.ttf") format("truetype");
}

@font-face {
  font-family: "Visuelt";
  src: url("./assets/fonts/Visuelt Bold.ttf") format("truetype");
  font-weight: bold;
}

* {
  font-family: "Visuelt", Helvetica, Arial, sans-serif !important;
}

/* width */
::-webkit-scrollbar {
  width: 14px;
}

/* Track */
::-webkit-scrollbar-track {
  background: $orange;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: $red;
  border-radius: 0.3em;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: $redish;
}

body {
  background-color: #ffffff;
}

#app {
  font-family: "Visuelt", Helvetica, Arial, sans-serif !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

.hubu-red {
  color: $red;
  font-weight: bold;
}

.madeby {
  color: #333;

  font-size: 22px;

  img {
    width: 75px;
  }
}

.grey {
  color: #333;
}

.madeby:hover {
  color: #333;
  text-decoration: none;
}

.footer-logo {
  width: 100px;
}

.text-green{
  color: $green;
}

.text-red{
  color: $red;
}

.bg-red {
  background-color: $red;
}

.bg-white{
  background-color: $white;
}

.text-white {
  color: $white;
}

.btn-outline-red {
  border: 1px solid $red;
  background-color: $white;
  color: $red;
}

.btn-outline-red:hover {
  background-color: $red;
  color: $white;
}

.btn-outline-blue {
  border: 1px solid $blue;
  background-color: $white;
  color: $blue;
}

.btn-outline-blue:hover {
  background-color: $blue;
  color: $white;
}

.btn-outline-green {
  border: 1px solid $green;
  background-color: $white;
  color: $green;
}

.btn-outline-green:hover {
  background-color: $green;
  color: $white;
}

.btn-outline-yellow {
  border: 1px solid $yellow;
  background-color: $white;
  color: $yellow;
}

.btn-outline-yellow:hover {
  background-color: $yellow;
  color: $white;
}

.btn-dark {
  background-color: $white;
  color: $black;
}

.btn-dark:hover {
  background-color: $black;
  color: $white;
}

.btn-red {
  border: 1px solid $red !important;
  background-color: $red;
  color: $white !important;
}

.btn-red:hover {
  background-color: $red;
  color: $white !important;
}
</style>