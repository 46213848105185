/**
 * Height fix for cards abs position
 * @return int ret as pixel height
 */
function FixHeight () {
  //Get a card height
  var cardstackheight = document.getElementsByClassName("card")[0];
  var madeby = document.getElementById("made-by");
  //Check if hubu is displayed
  if(typeof madeby !== "undefined" && madeby !== null) {
    madeby = madeby.offsetHeight;
  }else{
    madeby = 80;
  }
  var ret = 0;
  //Check if in game or not
  if(typeof cardstackheight !== "undefined") {
    ret = cardstackheight.offsetHeight + madeby + 100;
  }else{
    ret = 680;
  }
    
  return ret;
}

export default FixHeight;