/**
 * Vuex Store for var manipulation
 */
import Vue from "vue";
import Vuex from "vuex";
import Cookies from "js-cookie";
import { actions } from "./actions";
import { getters } from "./getters";
import { mutations } from "./mutations";
import { state } from "./state";

Vue.use(Cookies);
Vue.use(Vuex);

const store = new Vuex.Store({
  state,
  getters,
  actions,
  mutations,
});

export default store;