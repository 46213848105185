<template>
  <a :href="appText('Buy_url')">
    <div
      v-if="!$store.getters.loggedIn"
      class="announce"
    >
      {{ appText('announcement') }}
    </div>
  </a>
</template>

<script>
export default {
  name: "Announce",
  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables.scss";

.announce {
  width: 100%;
  background-color: $red;
  color: $white;
  padding: 10px;
  text-align: center;
}

a{
  text-decoration: none !important;
}
</style>