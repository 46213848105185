import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "../store/store";
import i18n from "../i18n";
import Cookies from "js-cookie";
import IsMobile from "../Helpers/IsMobile";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: "history",
});

router.beforeResolve((to, from, next) => {
  if (to.name) {
    store.dispatch("pageLoading");
  }
  next();
});

router.beforeEach((to, from, next) => {
  const pageTitle = to.matched.slice().reverse().find(r => r.meta);
  if (pageTitle) document.title = pageTitle.meta.title;
  let language = to.params.lang;
  if (!language) {
    language = "fr";
  }
  i18n.locale = language == "fr" || language == "en" ? language : "fr";
  Cookies.set("lang", i18n.locale );
  Cookies.set("mobile", IsMobile());
  store.dispatch("setRoute", from);
  store.dispatch("setCurrent", to);
  next();
});

router.afterEach(() => {
  store.dispatch("pageNotLoading");
});

const routerMix = Vue.mixin({
  beforeRouteEnter (to, from, next) {
    next();
  },
});
export { router, routerMix };