import Cookies from "js-cookie";

export const state = {
  organizations: [],
  textsLoaded: false,
  games: [],
  wink: false,
  shuffled: true,
  quotes: [],
  heightFix: "auto",
  appTexts: {},
  faq: [],
  gametype: "standard",
  selectedDecks: [],
  prevRoute: null,
  curRoute: null,
  err: null,
  social: null,
  jwt: Cookies.get("jwt") || null,
  load: false,
  instructions: [],
  organizationMembers: [],
  decks: {
    fr: [],
    en: [],
  },
  stackeddecks: {
    fr: [],
    en: [],
  },
  lang: Cookies.get("lang") || "fr",
  user: (Cookies.get("userdata")) ? JSON.parse(Cookies.get("userdata")) : null,
  standardTemplates: [],
  selectedDecksAndTemplates: null,
  isStillAllowedToPlay: false,
};