import axios from "axios";
import Cookies from "js-cookie";
import i18n from "../i18n";
import moment from "moment";

const API_URL = process.env.VUE_APP_API_URL;
const HEADERS = { Authorization: "Bearer " + process.env.VUE_APP_JWT };

export const actions = {
  newOrganizationGame (_, payload) {
    return new Promise((res, rej) => {
      axios.post(API_URL + "games", { user: payload.user, hat: payload.hat, organization: 42 }, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          res(resp.data);
        })
        .catch((err) => {
          console.log(err);
          rej(err);
        });
    });
  },
  setStandardTemplateName ({ commit }, val) {
    commit("SET_STANDARD_TEMPLATE_NAME", val);
  },
  removeLogo (_, logo) {
    return new Promise((res, rej) => {
      axios.delete(API_URL + "upload/files/" + logo, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then(() => {
          this.dispatch("getOrganizations")
            .then((resp) => {
              res(resp);
            })
            .catch((err) => {
              console.log(err);
              rej();
            });
        })
        .catch((err) => {
          console.log(err);
          rej();
        });
    });
  },
  emptyOrganizations ({ commit }) {
    commit("SET_ORGANIZATION_EMPTY");
  },
  async getStandardTemplates ({ commit }) {
    const { data } = await axios.get(API_URL + "card-templates?_locale=" + i18n.locale, { headers: { Authorization: "Bearer " + this.state.jwt } });
    commit("SET_STANDARD_TEMPLATES", data);
  },
  getTemplates ({ commit }) {
    return new Promise((res,rej) => {
      axios.get(API_URL + "templates", { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          commit("SET_TEMPLATES", resp.data);
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  deleteTemplate (_, id) {
    return new Promise((res, rej) => {
      axios.delete(API_URL + "templates/" + id, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  clearTemplate (_, data) {
    var bodyFormData = new FormData();
    const organization = typeof this.getters.getOrganizations == "object" ? this.getters.getOrganizations[0] : [];
    const user = this.getters.getUserData;

    data.users_permissions_user = user.id;
    data.organization = organization.id;

    bodyFormData.append("data", JSON.stringify(data));

    return new Promise((res, rej) => {
      axios.put(API_URL + "templates/" + data.id, bodyFormData, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  updateTemplate ({ commit }, data) {
    var bodyFormData = new FormData();
    const organization = typeof this.getters.getOrganizations == "object" ? this.getters.getOrganizations[0] : [];
    const user = this.getters.getUserData;

    data.users_permissions_user = user.id;
    data.organization = organization.id;

    bodyFormData.append("data", JSON.stringify(data));

    return new Promise((res, rej) => {
      axios.put(API_URL + "templates/" + data.id, bodyFormData, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          res(resp.data);
          commit("SET_NEW_TEMPLATE");
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  createTemplate ({ commit }, data) {
    var bodyFormData = new FormData();
    const organization = typeof this.getters.getOrganizations == "object" ? this.getters.getOrganizations[0] : [];
    const user = this.getters.getUserData;

    data.users_permissions_user = user.id;
    data.organization = organization.id;

    bodyFormData.append("data", JSON.stringify(data));

    return new Promise((res, rej) => {
      axios.post(API_URL + "templates", bodyFormData, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          res(resp.data);
          commit("SET_NEW_TEMPLATE");
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  getRules ({ commit }) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "rules?_locale=" + i18n.locale, {})
        .then((resp) => {
          commit("SET_RULES", resp.data);
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  deleteOrganizationMember ({ commit }, data) {
    return new Promise((res, rej) => {
      axios.put(API_URL + "organizations/" + data.id, { users: [] }, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          commit();
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  updateOrganization ({ commit }, data) {
    return new Promise((res, rej) => {
      data.formData.append("data", JSON.stringify(data.organizationData));
      axios.put(API_URL + "organizations/" + data.organizationData.id, data.formData,
        { 
          headers: { Authorization: "Bearer " + this.state.jwt },
        })
        .then((resp) => {
          commit("UPDATE_ORGANIZATION", resp.data);
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  getOrganizations ({ commit }) {
    return new Promise((res, rej) => {
      if (this.getters.loggedIn) {
        axios.get(API_URL + "organizations", { headers: { Authorization: "Bearer " + this.state.jwt } })
          .then((resp) => {
            commit("SET_ORGANIZATIONS", resp.data);
            res(resp.data);
          })
          .catch((err) => {
            rej(err);
          });
      } else {
        rej();
      }
    });
  },
  addUser_step_1 (_, data) {
    return new Promise((res, rej) => {
      var username = data.email.split("@");
      if(typeof username === "object") {
        if(username.length == 2) {
          username = username[0];
        }
      }

      axios.post(API_URL + "organizations/user", { organization: data.organization, user: { username: username, email: data.email, password: data.password, lang: i18n.locale, first_name: data.first_name, last_name: data.last_name }, role: data.role },  { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  addUser_step_2 ({ commit }, data) {
    return new Promise((res, rej) => {
      axios.put(API_URL + "organizations/" + data.organizationId, { users:  data.members  })
        .then((resp) => {
          commit("ADD_ORGANIZATION_MEMBER", resp.data);
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  getOrganizationMembers ({ commit }, id) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "organizations/" + id, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          commit("SET_ORGANIZATION_MEMBERS", resp.data);
          res(resp.data);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  verifyAccount (_, data) {
    return new Promise((res, rej) => {
      axios.post(API_URL + "utilities/verifyAccount", data)
        .then((resp) => {
          res(resp);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  confirmAccount ({ commit }, token) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "auth/email-confirmation?confirmation=" + token)
        .then((resp) => {
          res(resp, commit);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  updateAccount ({ commit }, data) {
    return new Promise((res, rej) => {
      data.identifier = this.state.user.email;
      axios.put(API_URL + "users/" + this.state.user.id, data, { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          res(resp, commit);
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  getMyGames ({ commit }) {
    const twoHoursAgo = moment().subtract(2, "hours");
    return this.state.user?.id ?  new Promise((res, rej) => {
      axios.get(API_URL + "games?created_by_hat=" + this.state.user.hats[0].id + "&endedAt_gt=" + twoHoursAgo.toDate().toISOString(), { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          if (resp.status == 200) {
            commit("SET_MY_GAMES", resp.data);
            res(resp.data);
          } else {
            rej(resp.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    }) : [];
  },
  setWink ({ commit }, v) {
    commit("SET_WINK", v);
  },
  setShuffled ({ commit }, v) {
    commit("SET_SHUFFLED", v);
  },
  setSelectedDecks ({ commit }, v) {
    commit("SET_DECKS_FROM_STORE", v);
  },
  getQuotes ({ commit }) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "quotes")
        .then((resp) => {
          if (resp.status == 200) {
            commit("SET_QUOTES", resp.data);
            res(resp);
          } else {
            rej(resp.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  resetPassword (_, data) {
    return new Promise((res, rej) => {
      axios.post(API_URL + "organizations/user/resetPassword", { password: data.password, email: data.email, resetPasswordToken: data.resetPasswordToken })
        .then((resp) => {
          if (resp.status == 200) {
            res(resp.data);
          } else {
            rej(resp.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  forgotPassword (_, data) {
    return new Promise((res, rej) => {
      axios.post(API_URL + "organizations/user/forgotPassword", { email: data.email })
        .then((resp) => {
          if (resp.status == 200) {
            res(resp.data);
          } else {
            rej(resp.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  getHeight ({ commit }) {
    commit("SET_HEIGHT");
  },
  getAppTexts ({ commit }) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "app-text?_locale=" + i18n.locale)
        .then((resp) => {
          if (resp.status == 200) {
            commit("SET_APP_TEXTS", resp.data);
            res(resp);
          } else {
            rej(resp.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  getFaq ({ commit }) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "faq?_locale=" + i18n.locale)
        .then((resp) => {
          if (resp.status == 200) {
            commit("SET_FAQ", resp.data);
            res(resp);
          } else {
            rej(resp.data);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  setGameType ({ commit }, val) {
    commit("SET_GAME_TYPE", val);
  },
  setGameDecks ({ commit }, payload) {
    var decks = payload.decks;
    if(!this.getters.loggedIn) {
      var tmpCards = [];
              
      payload.decks.forEach((deck, deckIndex) => {
        tmpCards[deckIndex] = [];
        payload.decks[deckIndex].cards.forEach((card) => {
          if(card.demo) {
            tmpCards[deckIndex].push(card);
          }
        });
        payload.decks[deckIndex].cards = tmpCards[deckIndex];
      });
    }
          
    return new Promise((res) => {
      commit("SET_SHUFFLED", payload.shuffled);
      commit("SET_SELECTED_DECKS", payload);
      res(decks);
    });
  },
  getInstructions ({ commit }) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "instructions?_locale=" + i18n.locale)
        .then((resp) => {
          commit("SET_INSTRUCTIONS", resp.data);
          res(resp.data);
        }).catch((err) => {
          rej(err);
        });
    });
  },
  getDecks ({ commit }) {
    var hdr = {};
    if (this.getters.loggedIn) {
      hdr = { headers: { Authorization: "Bearer " + this.state.jwt, hat: `${this.getters.user.hats[0].id}` } };
    }

    return new Promise((res, rej) => {
      const setDeck = lang => (resp) => {
        if (resp.status === 200) {
          var tmpdecks = { ...this.state.decks, [lang]: [] };
          var decks = resp.data;
          for (var i = 0; i < decks.length; i++) {
            tmpdecks[decks[i].locale].push(decks[i]);
          }
          tmpdecks.en.sort(function (a, b) {
            if (typeof a.subtitle == "undefined")
              return -1;
            if (a.subtitle < b.subtitle)
              return -1;
            if (a.subtitle > b.subtitle)
              return 1;
            return 0;
          });
          tmpdecks.fr.sort(function (a, b) {
            if (typeof a.subtitle == "undefined")
              return -1;
            if (a.subtitle < b.subtitle)
              return -1;
            if (a.subtitle > b.subtitle)
              return 1;
            return 0;
          });
  
          commit("SET_DECKS", tmpdecks);
          res(tmpdecks);
        } else {
          rej(resp);
        }
      };
      axios.get(API_URL + "decks?_locale=en", hdr)
        .then(setDeck("en"));
      axios.get(API_URL + "decks?_locale=fr", hdr)
        .then(setDeck("fr"));
    });
  },
  setCurrent ({ commit }, val) {
    commit("SET_CURRENT", val);
  },
  setRoute ({ commit }, val) {
    commit("SET_ROUTE", val);
  },
  logoutUser ({ commit }) {
    return new Promise((res, rej) => {
      if (this.getters.loggedIn) {
        Cookies.remove("jwt");
        Cookies.remove("userdata");
        commit("LOGOUT_USER");
        res("logged out");
      }else{
        rej("Not logged In");
      }
      res();
    });
  },
  userRegister (_, data) {
    return new Promise((res, rej) => {
      axios.post(API_URL + "webhooks/setUserFirstPassword", { user: { email: data.email, password: data.password, confirmationToken: data.confirmationToken } }, { header: HEADERS })
        .then((resp) => {
          if (resp.status == 200) {
            res(resp.data);
          } else {
            rej(resp);
          }
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  userLogin ({ commit }, userdata) {
    const identifier = userdata.email;
    const password = userdata.password;
    return new Promise((res, rej) => {
      axios.post(API_URL + "auth/local", { identifier, password }, { header: HEADERS }).then((resp) => {
        if (resp.status == 200) {
          const jwt = resp.data.jwt;
          Cookies.set("jwt", jwt);
          var tmp = resp.data.user;
          commit("LOGIN_USER", resp.data);
          commit("SET_MY_GAMES", tmp);
          delete tmp.orders;
          delete tmp.games;
          Cookies.set("userdata", JSON.stringify(resp.data.user));
          res(resp.data);
        } else {
          rej(resp);
        }
      }).catch((err) => {
        rej(err);
      });
    });
  },
  getMe ({ commit }) {
    return new Promise((res, rej) => {
      axios.get(API_URL + "hats/me", { headers: { Authorization: "Bearer " + this.state.jwt } })
        .then((resp) => {
          if (resp.status != 200) {
            rej(resp.data);
          }
          i18n.locale = resp.data.lang;
          commit("LOGIN_USER", { user: { ...resp.data, hats: [resp.data.hats] }, jwt: Cookies.get("jwt") });
          res();
        })
        .catch((err) => {
          rej(err);
        });
    });
  },
  pageNotLoading ({ commit }) {
    commit("PAGE_NOT_LOADING");
  },
  pageLoading ({ commit }) {
    commit("PAGE_LOADING");
  },
  deleteUser (_, { id: userId }) {
    return axios.delete(API_URL + `user-roles/${userId}`, { hat: { id: userId } }, { headers: { Authorization: "Bearer " + this.state.jwt } });
  },
  setSelectedDecksAndTemplates ({ commit }, setSelectedDecksAndTemplates) {
    commit("SET_SELECTED_DECKS_AND_TEMPLATES", setSelectedDecksAndTemplates);
  },
  async isStillAllowedToPlay ({ commit, dispatch }){
    const games = await dispatch("getMyGames");
    const isStillAllowedToPlay = games.length > 0;

    commit("IS_STILL_ALLOWED_TO_PLAY", { value: isStillAllowedToPlay });

    return isStillAllowedToPlay;
  },
  gamesPlayed () {
    return  axios.get(API_URL + "organizations/count", { headers: { Authorization: "Bearer " + this.state.jwt } });
  },
  async allGamesPlayed (_, { hats }) {
    const requests = hats.map(async (hat) => {
      const gamesPlayed = await axios.get(API_URL + `games/count?created_by_hat=${hat}`, { headers: { Authorization: "Bearer " + this.state.jwt } });
      return { id: hat, games: gamesPlayed.data };
    });
    const result = await Promise.all(requests);

    return result.reduce((games, game) => ({ ...games, [game.id]: game.games }), {});
  },
};