import Shuffle from "../Helpers/Shuffle";
import FixHeight from "../Helpers/FixHeight";

export const mutations = {
  SET_STANDARD_TEMPLATE_NAME (state, val) {
    state.standardTemplateName = val;
  },
  SET_ORGANIZATION_EMPTY (state) {
    state.organizations = [];
  },
  SET_STANDARD_TEMPLATES (state, val) {
    state.standardTemplates = val;
  },
  SET_TEMPLATES (state, val) {
    state.templates = val;
  },
  SET_NEW_TEMPLATE () {

  },
  UPDATE_ORGANIZATION (state, val) {
    state.organizations.name = val;
  },
  SET_ORGANIZATIONS (state, val) {
    state.organizations = val;
  },
  ADD_ORGANIZATION_MEMBER (state, val) {
    state.organizationMembers.push(val);
  },
  SET_ORGANIZATION_MEMBERS (state, val) {
    state.organizationMembers = val;
  },
  SET_CURRENT (state, val) {
    state.curRoute = val;
  },
  SET_ROUTE (state, val) {
    state.prevRoute = val;
  },
  SET_SOCIAL (state, social) {
    state.social = social;
  },
  LOGOUT_USER (state) {
    state.jwt = null;
    state.user = null;
  },
  LOGIN_USER (state, data) {
    state.jwt = data.jwt;
    state.user = data.user;
  },
  REGISTER_ERROR (state, err) {
    state.err = err;
  },
  PAGE_LOADING (state) {
    state.load = true;
  },
  PAGE_NOT_LOADING (state) {
    setTimeout(() => {
      state.load = false;
    }, 200);
  },
  SET_DECKS (state, val) {
    state.decks = val;

    const locales = require.context("@/lang", true, /[A-Za-z0-9-_,\s]+\.json$/i);

    locales.keys().forEach((key) => {
      const matched = key.match(/([A-Za-z0-9-_]+)\./i);
      if (matched && matched.length > 1) {
        var tmp = [];
        const locale = matched[1];
        var decklang = val[locale];
        const colors = ["green", "blue", "yellow", "red"];
        decklang.forEach((v, i) => {
          for (var j = 0; j < v.cards.length; j++) {
            decklang[i].cards[j].palette = colors[i - 1];
          }
          tmp = tmp.concat(v.cards);
        });
        if (state.shuffled)
          state.stackeddecks[locale] = Shuffle(tmp);
        else
          state.stackeddecks[locale] = tmp;
      }
    });
  },
  SET_INSTRUCTIONS (state, val) {
    state.instructionsNew = val;
  },
  SET_INSTR (state, val) {
    var tmp = {};
    val.instruction.forEach((v) => {
      if (typeof tmp[v.lang] === "undefined") {
        tmp[v.lang] = new Array;
      }
      tmp[v.lang].push(v);
    });
    state.instructions = tmp;
  },
  SET_SELECTED_DECKS (state, payload) {
    state.selectedDecksProps = [];
    payload.decks.forEach((val) => {
      state.selectedDecksProps.push(val);
    });
          
    var val = payload.decks;
    var shuffled = payload.shuffled;
    state.selectedDecks = [];
    var tmp = [];
    if (val.length == 1) {
      state.gametype = "deck";
    } else if (val.length > 1) {
      state.gametype = "standard";
    }
    val.forEach((v) => {
      tmp = tmp.concat(v.cards);
    });
    if (shuffled) {
      state.selectedDecks = Shuffle(tmp);
    } else {
      state.selectedDecks = tmp;
    }
  },
  SET_GAME_TYPE (state, val) {
    state.gametype = val;
  },
  SET_FAQ (state, val) {
    var tmp = {};
    val.question.forEach((v) => {
      if (typeof tmp[val.locale] === "undefined") {
        tmp[val.locale] = new Array;
      }
      tmp[val.locale].push(v);
    });
    state.faq = tmp;
  },
  SET_APP_TEXTS (state, val) {
    var tmpval = {};
    var split = "";
    var titles = "";
    val.text.forEach((v) => {
      if (typeof tmpval[val.locale] === "undefined") {
        tmpval[val.locale] = new Object;
      }
      split = v.name.split("tutorial_");
      titles = v.name.split("title_tutorial_");
      if (split[0] == "" && !isNaN(split[1])) {
        if (typeof tmpval[val.locale]["videos"] === "undefined") {
          tmpval[val.locale]["videos"] = new Array;
        }
        tmpval[val.locale]["videos"].push(v);
      }

      if (titles[0] == "" && !isNaN(titles[1])) {
        if (typeof tmpval[val.locale]["video_titles"] === "undefined") {
          tmpval[val.locale]["video_titles"] = new Array;
        }
        tmpval[val.locale]["video_titles"].push(v);
      }
      tmpval[val.locale][v.name] = v;
    });
    state.appTexts = tmpval;
    state.textsLoaded = true;
  },
  SET_HEIGHT (state) {
    state.heightFix = FixHeight();
  },
  SET_QUOTES (state, val) {
    var tmp = {};
    val.forEach((v) => {
      if (typeof tmp[v.locale] === "undefined") {
        tmp[v.locale] = new Array;
      }
      tmp[v.locale].push(v);
    });
    state.quotes = tmp;
  },
  FORCE_SET_QUOTES (state, val) {
    state.quotes = val;
  },
  SET_DECKS_FROM_STORE (state, val) {
    if (state.shuffled) {
      state.selectedDecks = Shuffle(val);
    } else {
      state.selectedDecks = val;
    }
  },
  SET_SHUFFLED (state, val) {
    state.shuffled = val;
  },
  SET_WINK (state, val) {
    state.wink = val;
  },
  SET_MY_GAMES (state, val) {
    state.user.games = val;
    state.games = val;
  },
  SET_RULES (state, val) {
    state.rules = val;
  },
  SET_SELECTED_DECKS_AND_TEMPLATES (state, val) {
    state.selectedDecksAndTemplates = val;
  },
  IS_STILL_ALLOWED_TO_PLAY (state, { value }) {
    state.isStillAllowedToPlay = value;
  },
};