<template>
  <div>
    <b-navbar
      toggleable="lg"
      type="dark"
      variant="faded"
      :class="{
        'p-5': !IsMobile,
      }"
    >
      <b-col align="left">
        <a
          v-if="showBack()"
          href="javascript:void(0);"
          class="color-red brand-bold back-btn"
          @click="goBack()"
        >
          <font-awesome-icon
            class="share-icon"
            :class="{
              mobileIcon: IsMobile,
            }"
            :icon="{ prefix: 'fas', iconName: 'arrow-left' }"
          />
        </a>
        
        <template v-if="organizations.length > 0">
          <template v-if="organizationLogo">
            <router-link
              v-if="organizations[0].logo"
              tag="a"
              :to="{ name: 'Home' }"
            >
              <img
                class="company-logo"
                :src="organizationLogo"
                :style="organizationLogo && 'height: 32px;'"
              >
            </router-link>
          </template>

          <template v-else>
            <router-link
              tag="a"
              :to="{ name: 'Home' }"
            >
              <img
                class="company-logo"
                src="@/assets/hubu.webp"
                style="height: 20px;"
              >
            </router-link>
          </template>
        </template>

        <template v-else>
          <router-link
            tag="a"
            :to="{ name: 'Home' }"
          >
            <img
              class="company-logo"
              src="@/assets/hubu.webp"
              style="height: 20px;"
            >
          </router-link>
        </template>
      </b-col>
      <b-col align="right">
        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
      </b-col>

      <b-collapse
        id="nav-collapse"
        is-nav
      >
        <!-- Right aligned nav items -->

        <!-- Displayed only when in game -->
        <b-navbar-nav
          v-if="
            $route.name == 'PlayStandard' ||
              $route.name == 'Instructions' ||
              $route.name == 'PlayByDeck'
          "
          class="ml-auto"
        >
          <b-row v-if="IsMobile">
            <b-col>
              <a
                v-if="links['linkedin']"
                class="social-link"
                :class="socialAlign == 'left' ? 'd-none' : ''"
                :href="links['linkedin']"
                target="_blank"
              >
                <font-awesome-icon
                  class="share-icon"
                  :class="{
                    mobileIcon: IsMobile,
                  }"
                  :icon="{ prefix: 'fab', iconName: 'linkedin' }"
                />
              </a>
              <a
                v-if="links['facebook']"
                class="social-link"
                :class="socialAlign == 'left' ? 'd-none' : ''"
                :href="links['facebook']"
                target="_blank"
              >
                <font-awesome-icon
                  class="share-icon"
                  :class="{
                    mobileIcon: IsMobile,
                  }"
                  :icon="{ prefix: 'fab', iconName: 'facebook' }"
                />
              </a>
              <a
                v-if="links['instagram']"
                class="social-link"
                :class="socialAlign == 'left' ? 'd-none' : ''"
                :href="links['instagram']"
                target="_blank"
              >
                <font-awesome-icon
                  class="share-icon"
                  :class="{
                    mobileIcon: IsMobile,
                  }"
                  :icon="{ prefix: 'fab', iconName: 'instagram' }"
                />
              </a>
            </b-col>
          </b-row>

          <!-- <b-nav-item>
            <Counter />
          </b-nav-item> -->

          <b-nav-item
            v-if="loggedIn"
            target="_blank"
            tag="b-nav-item"
            :href="tutorialsLink[$i18n.locale]"
          >
            {{ $t("tutorials") }}
          </b-nav-item>

          <b-nav-item
            v-if="!loggedIn"
            :href="appText('Buy_url')"
          >
            <b-button
              class="small"
              variant="red"
            >
              {{ $t("auth.register") }}
            </b-button>
          </b-nav-item>

          <router-link
            :to="{ name: 'Rules' }"
            tag="b-nav-item"
          >
            {{ $t("rules") }}
          </router-link>

          <router-link
            :to="{ name: 'Faq' }"
            tag="b-nav-item"
          >
            {{ $t("faq") }}
          </router-link>

          <LanguageSwitcher @languageChange="languageChange" />

          <Auth />
        </b-navbar-nav>

        <!-- The actual menu -->
        <b-navbar-nav
          v-if="
            $route.name != 'PlayStandard' &&
              $route.name != 'Instructions' &&
              $route.name != 'PlayByDeck'
          "
          class="ml-auto"
        >
          <!-- <b-nav-item>
            <Counter />
          </b-nav-item> -->

          <b-nav-item
            v-if="loggedIn"
            tag="b-nav-item"
            :href="tutorialsLink[$i18n.locale]"
            target="_blank"
          >
            {{ $t("tutorials") }}
          </b-nav-item>

          <b-nav-item
            v-if="!loggedIn"
            :href="appText('Buy_url')"
          >
            <b-button
              class="small"
              variant="red"
            >
              {{ $t("auth.register") }}
            </b-button>
          </b-nav-item>

          <router-link
            :to="{ name: 'Rules' }"
            tag="b-nav-item"
          >
            {{ $t("rules") }}
          </router-link>

          <router-link
            :to="{ name: 'Faq' }"
            tag="b-nav-item"
          >
            {{ $t("faq") }}
          </router-link>

          <LanguageSwitcher @languageChange="languageChange" />

          <Auth />
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
import Cookies from "js-cookie";
import LanguageSwitcher from "./LanguageSwitcher";
import Auth from "./Auth/Auth";
import eventBus from "../components/Helpers/eventBus";
import { mapGetters } from "vuex";

export default {
  name: "Navbar",
  components: {
    LanguageSwitcher,
    Auth,
  },
  data () {
    return {
      links: {},
      tutorialsLink: {
        fr: "https://experience.hubu.ca/tutoriels-teamtalk/",
        en: "https://experience.hubu.ca/teamtalk-tutorials/",
      },
    };
  },

  computed: {
    ...mapGetters(["organizationLogo"]),
    organizations: {
      get () {
        return this.$store.getters.getOrganizations;
      },
    },
    loggedIn () {
      return this.$store.getters.loggedIn;
    },
    IsMobile () {
      return Cookies.get("mobile") === "false" ? false : true;
    },
    socialAlign () {
      if (
        this.$route.name == "PlayByDeck" ||
        this.$route.name == "PlayStandard" ||
        this.$route.name == "Instructions"
      ) {
        return "right";
      }

      return "left";
    },
  },

  mounted () {
    this.handleUserLoginLogout();
  },

  methods: {
    handleUserLoginLogout () {
      eventBus.$on("userLoggedOut", () => {
        this.$store.dispatch("emptyOrganizations");
      });

      eventBus.$on("userLoggedIn", () => {
        this.$store.dispatch("getOrganizations");
      });
    },
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object" ? this.$store.getters.getAppTexts[text].content : text;
    },
    languageChange () {
      this.$emit("languageChange");
    },
    goBack () {
      this.$router.go(-1);
    },
    showBack () {
      return this.$store.state.curRoute.name !== "Home" &&
        this.$store.state.curRoute.name !== "" &&
        this.$store.state.curRoute.name !== null
        ? 1
        : 0;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.color-red {
  color: $red !important;
}
.brand-bold {
  font-weight: 800;
}
.semibold {
  font-weight: 600;
}
.nav-link,
.nav-link a {
  color: $red !important;
}

.logo {
  width: 90px;
}

.sloan {
  display: block;
  font-size: 14px;
}
.navbar-toggler {
  background-color: $red !important;
}

.bg-red {
  background-color: $red !important;
  border-color: $red !important;
  color: $white;
}

.share-icon {
  font-size: 32px;
  margin: 5px;
  color: $red;
}

.back-btn {
  font-size: 32px;
}

.width-auto {
  width: auto !important;
}

.social-link {
  width: 40px;
}

.mobileIcon {
  font-size: 24px !important;
}

.hubu-btn {
  background-color: $red;
  color: $white;
  border-color: $red;
}

.hubu-btn:disabled {
  background-color: $black !important;
  color: $white;
  opacity: 1;
  border-color: $black;
}

.small {
  height: calc(40px - 1rem) !important;
  padding: 1px 10px !important;
  margin: 0px !important;
  font-size: 16px !important;
  line-height: 16px !important;
}

.company-logo {
  margin: 0px 0px 24px 15px;
}
</style>