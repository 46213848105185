<template>
  <div>
    <router-link
      v-if="!loggedIn"
      :to="{ name: 'Login' }"
      tag="b-nav-item"
    >
      <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user' }" />&nbsp;
      {{ $t("auth.login") }}
    </router-link>
    <b-nav-item-dropdown
      v-if="loggedIn"
      right
    >
      <!-- Using 'button-content' slot -->
      <template #button-content>
        <em>
          <font-awesome-icon :icon="{ prefix: 'fas', iconName: 'user' }" />&nbsp;
          <span v-if="loggedIn && user">{{ user.email }}</span>
          <span v-if="!loggedIn">{{ $t("auth.label") }}</span>
        </em>
      </template>

      <template v-if="loggedIn">
        <router-link
          :to="{ name: 'ChooseCards' }"
          tag="b-dropdown-item"
        >
          {{ appText("my_cards_title") }}
        </router-link>
        <router-link
          v-if="authorizations && authorizations.manageAccounts"
          :to="{ name: 'ManageAccounts' }"
          tag="b-dropdown-item"
        >
          {{ appText("manage_accounts_title") }}
        </router-link>
        <router-link
          :to="{ name: 'Account-View' }"
          tag="b-dropdown-item"
        >
          {{ $t("auth.account") }}
        </router-link>
        <router-link
          :to="{ name: 'Logout' }"
          tag="b-dropdown-item"
        >
          {{ $t("auth.logout") }}
        </router-link>
      </template>

      <!-- <template v-if="!loggedIn">
        <router-link :to="{name:'Login'}" tag="b-dropdown-item">
            {{$t('auth.login')}}
        </router-link>
        <b-dropdown-item :href="appText('Buy_url')">
            {{$t('auth.register')}}
        </b-dropdown-item>
      </template> -->
    </b-nav-item-dropdown>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "Auth",

  data () {
    return {};
  },
  computed: {
    ...mapGetters(["authorizations"]),
    loggedIn () {
      return this.$store.getters.loggedIn;
    },
    user () {
      return this.$store.getters.getUserData;
    },
  },
  created () {},

  methods: {
    appText (text) {
      return typeof this.$store.getters.getAppTexts[text] == "object"
        ? this.$store.getters.getAppTexts[text].content
        : text;
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";
a {
  color: #212529 !important;
}

.dropdown-item:active,
.dropdown-item:hover {
  background-color: $orange !important;
  color: $red !important;
}
</style>
