var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-navbar',{class:{
      'p-5': !_vm.IsMobile,
    },attrs:{"toggleable":"lg","type":"dark","variant":"faded"}},[_c('b-col',{attrs:{"align":"left"}},[(_vm.showBack())?_c('a',{staticClass:"color-red brand-bold back-btn",attrs:{"href":"javascript:void(0);"},on:{"click":function($event){return _vm.goBack()}}},[_c('font-awesome-icon',{staticClass:"share-icon",class:{
            mobileIcon: _vm.IsMobile,
          },attrs:{"icon":{ prefix: 'fas', iconName: 'arrow-left' }}})],1):_vm._e(),(_vm.organizations.length > 0)?[(_vm.organizationLogo)?[(_vm.organizations[0].logo)?_c('router-link',{attrs:{"tag":"a","to":{ name: 'Home' }}},[_c('img',{staticClass:"company-logo",style:(_vm.organizationLogo && 'height: 32px;'),attrs:{"src":_vm.organizationLogo}})]):_vm._e()]:[_c('router-link',{attrs:{"tag":"a","to":{ name: 'Home' }}},[_c('img',{staticClass:"company-logo",staticStyle:{"height":"20px"},attrs:{"src":require("@/assets/hubu.webp")}})])]]:[_c('router-link',{attrs:{"tag":"a","to":{ name: 'Home' }}},[_c('img',{staticClass:"company-logo",staticStyle:{"height":"20px"},attrs:{"src":require("@/assets/hubu.webp")}})])]],2),_c('b-col',{attrs:{"align":"right"}},[_c('b-navbar-toggle',{attrs:{"target":"nav-collapse"}})],1),_c('b-collapse',{attrs:{"id":"nav-collapse","is-nav":""}},[(
          _vm.$route.name == 'PlayStandard' ||
            _vm.$route.name == 'Instructions' ||
            _vm.$route.name == 'PlayByDeck'
        )?_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.IsMobile)?_c('b-row',[_c('b-col',[(_vm.links['linkedin'])?_c('a',{staticClass:"social-link",class:_vm.socialAlign == 'left' ? 'd-none' : '',attrs:{"href":_vm.links['linkedin'],"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"share-icon",class:{
                  mobileIcon: _vm.IsMobile,
                },attrs:{"icon":{ prefix: 'fab', iconName: 'linkedin' }}})],1):_vm._e(),(_vm.links['facebook'])?_c('a',{staticClass:"social-link",class:_vm.socialAlign == 'left' ? 'd-none' : '',attrs:{"href":_vm.links['facebook'],"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"share-icon",class:{
                  mobileIcon: _vm.IsMobile,
                },attrs:{"icon":{ prefix: 'fab', iconName: 'facebook' }}})],1):_vm._e(),(_vm.links['instagram'])?_c('a',{staticClass:"social-link",class:_vm.socialAlign == 'left' ? 'd-none' : '',attrs:{"href":_vm.links['instagram'],"target":"_blank"}},[_c('font-awesome-icon',{staticClass:"share-icon",class:{
                  mobileIcon: _vm.IsMobile,
                },attrs:{"icon":{ prefix: 'fab', iconName: 'instagram' }}})],1):_vm._e()])],1):_vm._e(),(_vm.loggedIn)?_c('b-nav-item',{attrs:{"target":"_blank","tag":"b-nav-item","href":_vm.tutorialsLink[_vm.$i18n.locale]}},[_vm._v(" "+_vm._s(_vm.$t("tutorials"))+" ")]):_vm._e(),(!_vm.loggedIn)?_c('b-nav-item',{attrs:{"href":_vm.appText('Buy_url')}},[_c('b-button',{staticClass:"small",attrs:{"variant":"red"}},[_vm._v(" "+_vm._s(_vm.$t("auth.register"))+" ")])],1):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'Rules' },"tag":"b-nav-item"}},[_vm._v(" "+_vm._s(_vm.$t("rules"))+" ")]),_c('router-link',{attrs:{"to":{ name: 'Faq' },"tag":"b-nav-item"}},[_vm._v(" "+_vm._s(_vm.$t("faq"))+" ")]),_c('LanguageSwitcher',{on:{"languageChange":_vm.languageChange}}),_c('Auth')],1):_vm._e(),(
          _vm.$route.name != 'PlayStandard' &&
            _vm.$route.name != 'Instructions' &&
            _vm.$route.name != 'PlayByDeck'
        )?_c('b-navbar-nav',{staticClass:"ml-auto"},[(_vm.loggedIn)?_c('b-nav-item',{attrs:{"tag":"b-nav-item","href":_vm.tutorialsLink[_vm.$i18n.locale],"target":"_blank"}},[_vm._v(" "+_vm._s(_vm.$t("tutorials"))+" ")]):_vm._e(),(!_vm.loggedIn)?_c('b-nav-item',{attrs:{"href":_vm.appText('Buy_url')}},[_c('b-button',{staticClass:"small",attrs:{"variant":"red"}},[_vm._v(" "+_vm._s(_vm.$t("auth.register"))+" ")])],1):_vm._e(),_c('router-link',{attrs:{"to":{ name: 'Rules' },"tag":"b-nav-item"}},[_vm._v(" "+_vm._s(_vm.$t("rules"))+" ")]),_c('router-link',{attrs:{"to":{ name: 'Faq' },"tag":"b-nav-item"}},[_vm._v(" "+_vm._s(_vm.$t("faq"))+" ")]),_c('LanguageSwitcher',{on:{"languageChange":_vm.languageChange}}),_c('Auth')],1):_vm._e()],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }